import AdminComponent from '@/components/dashboard/AdminComponent';
import HomeView from '@/views/HomeView';
import LoginView from '@/views/LoginView';

import AccountBank from '@/router/modules/account-bank';
import AccountType from '@/router/modules/account-type';
import Brand from '@/router/modules/brand';
import Budget from '@/router/modules/budget';
import BudgetDelivery from '@/router/modules/budget-delivery';
import BudgetTemplate from '@/router/modules/budget-template';
import Category from '@/router/modules/category';
import Client from '@/router/modules/client';
import Employee from '@/router/modules/employee';
import EntranceExit from '@/router/modules/entrance-exit';
import EntranceExitPreview from '@/router/modules/entrance-exit-preview';
import Fleet from '@/router/modules/fleet';
import GuestSchedule from '@/router/modules/guest-schedule';
import Inventory from '@/router/modules/inventory';
import MySchedule from '@/router/modules/my-schedule';
import Product from '@/router/modules/product';
import Provider from '@/router/modules/provider';
import Receipt from '@/router/modules/receipt';
import ReportBudget from '@/router/modules/report-budget';
import ReportClient from '@/router/modules/report-client';
import ReportProduct from '@/router/modules/report-product';
import Schedule from '@/router/modules/schedule';
import Service from '@/router/modules/service';
import User from '@/router/modules/user';
import UserGroup from '@/router/modules/user-group';
import NameBudget from '@/router/modules/name-budget';
import budgetMaintenance from './modules/service-order';

const routes = [
  {
    path: '/',
    component: AdminComponent,
    children: [
      { path: 'login', name: 'login', component: LoginView },
      { path: '/', name: 'home', meta: { auth: true }, component: HomeView }
    ]
  },

  AccountBank,
  AccountType,
  Brand,
  Budget,
  budgetMaintenance,
  BudgetDelivery,
  BudgetTemplate,
  Category,
  Client,
  Employee,
  EntranceExit,
  EntranceExitPreview,
  Fleet,
  GuestSchedule,
  Inventory,
  MySchedule,
  Product,
  Provider,
  Receipt,
  ReportBudget,
  ReportClient,
  ReportProduct,
  Schedule,
  Service,
  User,
  UserGroup,
  NameBudget
];

export default routes;
